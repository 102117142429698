import { Role, Path, UserAccess } from "enums";
import locale from "localization";
import { GearIcon, VipIcon } from "images";
import styles from "../components/commons/sidebar/sidebar.module.scss";
const accessLevel = {
  viewAccess: true,
  modifyAccess: true,
  exportAccess: true,
  voidAccess: true,
};

const UserRoleAccess = [
  {
    label: locale.dashboard,
    link: Path.Slash,
    icon: "icon-dashboard",
    key: UserAccess.Dashboard,
    exact: true,
    items: [
      {
        key: UserAccess.Dashboard,
        permission: [
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
          {
            role: Role.Audit,
            ...accessLevel,
            modifyAccess: false,
            exportAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Accounting,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Marketing,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
          {
            role: Role.GEAD,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
        ],
      },
    ],
    permission: [Role.PortalAdmin, Role.Audit, Role.Accounting, Role.Marketing, Role.GEAD],
  },
  // {
  //   label: locale.myApproval,
  //   icon: "icon-approval",
  //   link: Path.MyApproval,
  //   exact: true,
  // },
  {
    label: locale.pricing,
    icon: "icon-pricing",
    link: Path.Pricing,
    key: UserAccess.Pricing,
    items: [
      // {
      //   label: locale.wholesalePrice,
      //   child: true,
      //   link: Path.WholesalePrice,
      //   key: "WHOLE_SALE_PRICE",
      //   isSeaoilRetail: true,
      // },
      {
        label: locale.fleetPrices,
        child: true,
        link: Path.FleetPrices,
        key: UserAccess.FleetPrices,
        permission: [
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
          {
            role: Role.RetailPricing,
            ...accessLevel,
          },
          {
            role: Role.Audit,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Accounting,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Marketing,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
        ],
      },
      {
        label: locale.pricingChanges,
        child: true,
        link: Path.PricingChanges,
        key: UserAccess.PricingChanges,
        permission: [
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
          {
            role: Role.RetailPricing,
            ...accessLevel,
          },
          {
            role: Role.Audit,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Accounting,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Marketing,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
        ],
      },
      {
        label: locale.doxNegotiatedDiscounts,
        child: true,
        link: Path.DoxNegotiatedDiscounts,
        key: UserAccess.DOXNegotiatedDiscounts,
        permission: [
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
          {
            role: Role.Audit,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Accounting,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Marketing,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
        ],
      },
      {
        label: locale.priceBuildUp,
        child: true,
        link: Path.PriceBuildUp,
        key: UserAccess.PriceBuildUp,
        permission: [
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
          {
            role: Role.Audit,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Accounting,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Marketing,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
        ],
      },
    ],
    permission: [Role.PortalAdmin, Role.RetailPricing, Role.Audit, Role.Accounting, Role.Marketing],
  },
  // {
  //   label: locale.inventory,
  //   icon: "icon-inventory",
  //   link: Path.Inventory,
  //   exact: true,
  // },
  // {
  //   label: locale.admin,
  //   icon: "icon-admin",
  //   link: Path.Admin,
  //   exact: true,
  // },
  {
    label: locale.payments,
    icon: "icon-payment",
    link: Path.Payments,
    key: UserAccess.Payments,
    isSeaoilRetail: true,
    items: [
      {
        label: locale.oilcoSettlementHistory,
        child: true,
        link: Path.SettlementHistory,
        key: UserAccess.SettlementHistory,
        permission: [
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
          {
            role: Role.Accounting,
            ...accessLevel,
            exportAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Audit,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Marketing,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
        ],
      },
      {
        label: locale.doxPremiumPayment,
        child: true,
        link: Path.PremiumPayout,
        key: UserAccess.PremiumPayout,
        permission: [
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
          {
            role: Role.Accounting,
            ...accessLevel,
            exportAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Audit,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Marketing,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
        ],
      },
      {
        label: locale.plcCashIn,
        child: true,
        link: Path.PLCCashIn,
        key: UserAccess.PLCCashIn,
        permission: [
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
          {
            role: Role.Audit,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Marketing,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
        ],
      },
      {
        label: locale.plbCashIn,
        child: true,
        link: Path.PLBCashIn,
        key: UserAccess.PLBCashIn,
        permission: [
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
          {
            role: Role.Audit,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Marketing,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
        ],
      },
    ],
    permission: [Role.PortalAdmin, Role.Accounting, Role.Audit, Role.Marketing],
  },
  {
    label: locale.fuel,
    icon: "icon-fuel",
    link: Path.ConsumerFuelPage,
    key: "CONSUMER_FUEL",
    items: [
      {
        label: locale.plcRedemption,
        link: Path.PLCRedemption,
        key: UserAccess.PLCRedemption,
        child: true,
        permission: [
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
          {
            role: Role.Audit,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Marketing,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
        ],
      },
      {
        label: locale.plcLOCQPayForFuel,
        link: Path.PLCLOCQPayForFuel,
        key: UserAccess.PLCLOCQPayForFuel,
        child: true,
        permission: [
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
          {
            role: Role.Audit,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Marketing,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
        ],
      },
      {
        label: locale.plbPayWithLOCQPayRedemption,
        link: Path.PLBPayWithLOCQPayRedemption,
        key: UserAccess.PLBPayWithLOCQPayRedemption,
        child: true,
        permission: [
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
          {
            role: Role.Audit,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Marketing,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
        ],
      },
      {
        label: locale.plbRedemption,
        link: Path.PLBRedemption,
        key: UserAccess.PLBRedemption,
        child: true,
        permission: [
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
          {
            role: Role.Audit,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Marketing,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
        ],
      },
    ],
    permission: [Role.PortalAdmin, Role.Audit, Role.Marketing],
  },
  {
    label: locale.accounts,
    icon: "icon-fleet-activity",
    key: UserAccess.Accounts,
    link: Path.Accounts,
    items: [
      {
        label: locale.cardManagement,
        child: true,
        link: Path.CardManagement,
        key: UserAccess.CardManagement,
        permission: [
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
          {
            role: Role.Operations,
            ...accessLevel,
            modifyAccess: false,
            exportAccess: false,
            voidAccess: false,
          },
          {
            role: Role.RetailPricing,
            ...accessLevel,
          },
          {
            role: Role.Audit,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Accounting,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Marketing,
            fullAccess: true,
          },
        ],
      },
      {
        label: locale.customerList,
        child: true,
        link: Path.CustomersList,
        key: UserAccess.CustomersList,
        permission: [
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
          {
            role: Role.Operations,
            ...accessLevel,
            modifyAccess: false,
            exportAccess: false,
            voidAccess: false,
          },
          {
            role: Role.RetailPricing,
            ...accessLevel,
          },
          {
            role: Role.Audit,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Accounting,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Marketing,
            fullAccess: true,
          },
        ],
      },
      {
        label: locale.fleetAccountList,
        child: true,
        link: Path.FleetAccounts,
        key: UserAccess.FleetAccounts,
        permission: [
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
          {
            role: Role.Operations,
            ...accessLevel,
            modifyAccess: false,
            exportAccess: false,
            voidAccess: false,
          },
          {
            role: Role.RetailPricing,
            ...accessLevel,
          },
          {
            role: Role.Audit,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Accounting,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Marketing,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
        ],
      },
      {
        label: locale.stationAccountList,
        child: true,
        link: Path.Station,
        isSeaoilRetail: true,
        key: UserAccess.StationAccounts,
        permission: [
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
          {
            role: Role.Marketing,
            ...accessLevel,
            fullAccess: true,
          },
          {
            role: Role.Operations,
            ...accessLevel,
            modifyAccess: false,
            exportAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Audit,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Accounting,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
        ],
      },
      {
        label: locale.kycSubmission,
        child: true,
        link: Path.KYCSubmissions,
        key: UserAccess.KycSubmissions,
        permission: [
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
          {
            role: Role.Marketing,
            ...accessLevel,
            fullAccess: true,
          },
        ],
      },
    ],
    permission: [
      Role.PortalAdmin,
      Role.Marketing,
      Role.Operations,
      Role.Audit,
      Role.RetailPricing,
      Role.Accounting,
    ],
  },
  {
    label: locale.campaignsAndPrograms,
    icon: "icon-campaigns",
    link: Path.Campaigns,
    key: UserAccess.CampaignsPrograms,
    isSeaoilRetail: true,
    items: [
      {
        label: locale.campaigns,
        child: true,
        link: Path.VoucherCampaign,
        key: UserAccess.VoucherCampaign,
        permission: [
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
          {
            role: Role.Finance,
            ...accessLevel,
            exportAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Marketing,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
          {
            role: Role.GEAD,
            fullAccess: true,
          },
        ],
      },
      {
        label: locale.vouchers,
        child: true,
        link: Path.Vouchers,
        key: UserAccess.Vouchers,
        permission: [
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
          {
            role: Role.Finance,
            ...accessLevel,
            exportAccess: false,
            voidAccess: false,
          },
          {
            role: Role.GEAD,
            fullAccess: true,
          },
        ],
      },
    ],
    permission: [Role.PortalAdmin, Role.Finance, Role.Marketing, Role.GEAD],
  },
  {
    label: locale.otherServices,
    customIcon: <img src={GearIcon} alt="gear" className={styles.customIcon} />,
    link: Path.ConsumerOtherServices,
    key: UserAccess.ConsumerOtherServices,
    items: [
      {
        label: locale.lubeServOnWheelsBookings,
        link: Path.LubeServOnWheelsBookings,
        key: UserAccess.LubeServOnWheelsBookings,
        child: true,
        permission: [
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
          {
            role: Role.LubeServ,
            ...accessLevel,
            modifyAccess: false,
            exportAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Audit,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Marketing,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
        ],
      },
      {
        label: locale.lubeServOnSite,
        link: Path.LubeServOnSiteBookings,
        key: UserAccess.LubeServOnSiteBookings,
        child: true,
        permission: [
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
          {
            role: Role.LubeServ,
            ...accessLevel,
            modifyAccess: false,
            exportAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Audit,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Marketing,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
        ],
      },

      {
        label: locale.rfidReloading,
        link: Path.ConsumerRFID,
        key: UserAccess.PlcRFIDLoading,
        child: true,
        permission: [
          { role: Role.PortalAdmin, fullAccess: true },
          {
            role: Role.Marketing,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
        ],
      },
      {
        label: locale.locqpayForNonFuelPLC,
        child: true,
        link: Path.NonFuelPLC,
        key: UserAccess.PLCNonFuel,
        permission: [
          { role: Role.PortalAdmin, fullAccess: true },
          {
            role: Role.Marketing,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Audit,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
        ],
      },
      {
        label: locale.locqpayForNonFuelPLB,
        child: true,
        link: Path.NonFuelPLB,
        key: UserAccess.PLBNonFuel,
        permission: [
          { role: Role.PortalAdmin, fullAccess: true },
          {
            role: Role.Marketing,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
          {
            role: Role.Audit,
            ...accessLevel,
            modifyAccess: false,
            voidAccess: false,
          },
        ],
      },
    ],
    permission: [Role.PortalAdmin, Role.Finance, Role.Marketing, Role.LubeServ, Role.Audit],
  },
  {
    label: locale.accessAuthorization,
    link: Path.AccessAuthorization,
    key: UserAccess.AccessAuthorization,
    icon: "icon-access",
    items: [
      {
        label: locale.userAccess,
        child: true,
        link: Path.UserAccess,
        key: UserAccess.UserAccess,
        permission: [
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
        ],
      },
    ],
    permission: [Role.PortalAdmin],
  },
  {
    label: locale.VIP,
    link: Path.VIPTransactionHistory,
    key: UserAccess.VIPTransactionHistory,
    customIcon: <img src={VipIcon} alt="vip" className={styles.customIcon} />,
    items: [
      {
        label: locale.transactionHistory,
        child: true,
        link: Path.VIPHistoryAwardPoints,
        key: UserAccess.VIPTransactionHistory,
        permission: [
          {
            role: Role.PortalAdmin,
            fullAccess: true,
          },
          {
            role: Role.Marketing,
            fullAccess: true,
          },
          {
            role: Role.Audit,
            fullAccess: false,
          },
        ],
      },
    ],
    permission: [Role.PortalAdmin, Role.Marketing, Role.Audit],
  },
];

export default UserRoleAccess;
